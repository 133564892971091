export const Milestones = () => import('../../components/Milestones.vue' /* webpackChunkName: "components/milestones" */).then(c => wrapFunctional(c.default || c))
export const AboutContent = () => import('../../components/about/AboutContent.vue' /* webpackChunkName: "components/about-content" */).then(c => wrapFunctional(c.default || c))
export const AboutGallery = () => import('../../components/about/AboutGallery.vue' /* webpackChunkName: "components/about-gallery" */).then(c => wrapFunctional(c.default || c))
export const AboutHero = () => import('../../components/about/AboutHero.vue' /* webpackChunkName: "components/about-hero" */).then(c => wrapFunctional(c.default || c))
export const AboutResults = () => import('../../components/about/AboutResults.vue' /* webpackChunkName: "components/about-results" */).then(c => wrapFunctional(c.default || c))
export const AboutTeam = () => import('../../components/about/AboutTeam.vue' /* webpackChunkName: "components/about-team" */).then(c => wrapFunctional(c.default || c))
export const AboutTeamPersonDialog = () => import('../../components/about/AboutTeamPersonDialog.vue' /* webpackChunkName: "components/about-team-person-dialog" */).then(c => wrapFunctional(c.default || c))
export const AboutValues = () => import('../../components/about/AboutValues.vue' /* webpackChunkName: "components/about-values" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleItem = () => import('../../components/articles/ArticlesArticleItem.vue' /* webpackChunkName: "components/articles-article-item" */).then(c => wrapFunctional(c.default || c))
export const ArticlesDetailContent = () => import('../../components/articles/ArticlesDetailContent.vue' /* webpackChunkName: "components/articles-detail-content" */).then(c => wrapFunctional(c.default || c))
export const ArticlesHero = () => import('../../components/articles/ArticlesHero.vue' /* webpackChunkName: "components/articles-hero" */).then(c => wrapFunctional(c.default || c))
export const ArticlesSubscribeForm = () => import('../../components/articles/ArticlesSubscribeForm.vue' /* webpackChunkName: "components/articles-subscribe-form" */).then(c => wrapFunctional(c.default || c))
export const CareerApplicantForm = () => import('../../components/career/CareerApplicantForm.vue' /* webpackChunkName: "components/career-applicant-form" */).then(c => wrapFunctional(c.default || c))
export const CareerDetailContent = () => import('../../components/career/CareerDetailContent.vue' /* webpackChunkName: "components/career-detail-content" */).then(c => wrapFunctional(c.default || c))
export const CareerDetailHero = () => import('../../components/career/CareerDetailHero.vue' /* webpackChunkName: "components/career-detail-hero" */).then(c => wrapFunctional(c.default || c))
export const CareerHero = () => import('../../components/career/CareerHero.vue' /* webpackChunkName: "components/career-hero" */).then(c => wrapFunctional(c.default || c))
export const CareerList = () => import('../../components/career/CareerList.vue' /* webpackChunkName: "components/career-list" */).then(c => wrapFunctional(c.default || c))
export const CareerListItem = () => import('../../components/career/CareerListItem.vue' /* webpackChunkName: "components/career-list-item" */).then(c => wrapFunctional(c.default || c))
export const ContactBox = () => import('../../components/contact/ContactBox.vue' /* webpackChunkName: "components/contact-box" */).then(c => wrapFunctional(c.default || c))
export const ContactCompany = () => import('../../components/contact/ContactCompany.vue' /* webpackChunkName: "components/contact-company" */).then(c => wrapFunctional(c.default || c))
export const ContactHero = () => import('../../components/contact/ContactHero.vue' /* webpackChunkName: "components/contact-hero" */).then(c => wrapFunctional(c.default || c))
export const ContactOffices = () => import('../../components/contact/ContactOffices.vue' /* webpackChunkName: "components/contact-offices" */).then(c => wrapFunctional(c.default || c))
export const EventAgenda = () => import('../../components/events/EventAgenda.vue' /* webpackChunkName: "components/event-agenda" */).then(c => wrapFunctional(c.default || c))
export const EventPast = () => import('../../components/events/EventPast.vue' /* webpackChunkName: "components/event-past" */).then(c => wrapFunctional(c.default || c))
export const EventUpcoming = () => import('../../components/events/EventUpcoming.vue' /* webpackChunkName: "components/event-upcoming" */).then(c => wrapFunctional(c.default || c))
export const EventVenue = () => import('../../components/events/EventVenue.vue' /* webpackChunkName: "components/event-venue" */).then(c => wrapFunctional(c.default || c))
export const EventsPast = () => import('../../components/events/EventsPast.vue' /* webpackChunkName: "components/events-past" */).then(c => wrapFunctional(c.default || c))
export const EventsUpcoming = () => import('../../components/events/EventsUpcoming.vue' /* webpackChunkName: "components/events-upcoming" */).then(c => wrapFunctional(c.default || c))
export const StartupsHero = () => import('../../components/founders/StartupsHero.vue' /* webpackChunkName: "components/startups-hero" */).then(c => wrapFunctional(c.default || c))
export const StartupsLabs = () => import('../../components/founders/StartupsLabs.vue' /* webpackChunkName: "components/startups-labs" */).then(c => wrapFunctional(c.default || c))
export const StartupsStaking = () => import('../../components/founders/StartupsStaking.vue' /* webpackChunkName: "components/startups-staking" */).then(c => wrapFunctional(c.default || c))
export const StartupsValues = () => import('../../components/founders/StartupsValues.vue' /* webpackChunkName: "components/startups-values" */).then(c => wrapFunctional(c.default || c))
export const FundsHero = () => import('../../components/funds/FundsHero.vue' /* webpackChunkName: "components/funds-hero" */).then(c => wrapFunctional(c.default || c))
export const FundsList = () => import('../../components/funds/FundsList.vue' /* webpackChunkName: "components/funds-list" */).then(c => wrapFunctional(c.default || c))
export const FundsListItem = () => import('../../components/funds/FundsListItem.vue' /* webpackChunkName: "components/funds-list-item" */).then(c => wrapFunctional(c.default || c))
export const HomeAbout = () => import('../../components/home/HomeAbout.vue' /* webpackChunkName: "components/home-about" */).then(c => wrapFunctional(c.default || c))
export const HomeArticles = () => import('../../components/home/HomeArticles.vue' /* webpackChunkName: "components/home-articles" */).then(c => wrapFunctional(c.default || c))
export const HomeFunds = () => import('../../components/home/HomeFunds.vue' /* webpackChunkName: "components/home-funds" */).then(c => wrapFunctional(c.default || c))
export const HomeGlobe = () => import('../../components/home/HomeGlobe.vue' /* webpackChunkName: "components/home-globe" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/home/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeMedia = () => import('../../components/home/HomeMedia.vue' /* webpackChunkName: "components/home-media" */).then(c => wrapFunctional(c.default || c))
export const HomeMilestones = () => import('../../components/home/HomeMilestones.vue' /* webpackChunkName: "components/home-milestones" */).then(c => wrapFunctional(c.default || c))
export const HomeValueAdd = () => import('../../components/home/HomeValueAdd.vue' /* webpackChunkName: "components/home-value-add" */).then(c => wrapFunctional(c.default || c))
export const InvestorsFunds = () => import('../../components/investors/InvestorsFunds.vue' /* webpackChunkName: "components/investors-funds" */).then(c => wrapFunctional(c.default || c))
export const InvestorsHero = () => import('../../components/investors/InvestorsHero.vue' /* webpackChunkName: "components/investors-hero" */).then(c => wrapFunctional(c.default || c))
export const InvestorsPortfolio = () => import('../../components/investors/InvestorsPortfolio.vue' /* webpackChunkName: "components/investors-portfolio" */).then(c => wrapFunctional(c.default || c))
export const PortfolioGrid = () => import('../../components/portfolio/PortfolioGrid.vue' /* webpackChunkName: "components/portfolio-grid" */).then(c => wrapFunctional(c.default || c))
export const PortfolioGridItem = () => import('../../components/portfolio/PortfolioGridItem.vue' /* webpackChunkName: "components/portfolio-grid-item" */).then(c => wrapFunctional(c.default || c))
export const PortfolioHero = () => import('../../components/portfolio/PortfolioHero.vue' /* webpackChunkName: "components/portfolio-hero" */).then(c => wrapFunctional(c.default || c))
export const StakingHero = () => import('../../components/staking/StakingHero.vue' /* webpackChunkName: "components/staking-hero" */).then(c => wrapFunctional(c.default || c))
export const StakingInstitutes = () => import('../../components/staking/StakingInstitutes.vue' /* webpackChunkName: "components/staking-institutes" */).then(c => wrapFunctional(c.default || c))
export const StakingQuestions = () => import('../../components/staking/StakingQuestions.vue' /* webpackChunkName: "components/staking-questions" */).then(c => wrapFunctional(c.default || c))
export const StakingQuestionsQuestion = () => import('../../components/staking/StakingQuestionsQuestion.vue' /* webpackChunkName: "components/staking-questions-question" */).then(c => wrapFunctional(c.default || c))
export const StakingServices = () => import('../../components/staking/StakingServices.vue' /* webpackChunkName: "components/staking-services" */).then(c => wrapFunctional(c.default || c))
export const StakingValidators = () => import('../../components/staking/StakingValidators.vue' /* webpackChunkName: "components/staking-validators" */).then(c => wrapFunctional(c.default || c))
export const StakingValidatorsValidator = () => import('../../components/staking/StakingValidatorsValidator.vue' /* webpackChunkName: "components/staking-validators-validator" */).then(c => wrapFunctional(c.default || c))
export const ArticleAuthor = () => import('../../components/ui/ArticleAuthor.vue' /* webpackChunkName: "components/article-author" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/ui/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbItem = () => import('../../components/ui/BreadcrumbItem.vue' /* webpackChunkName: "components/breadcrumb-item" */).then(c => wrapFunctional(c.default || c))
export const BurgerMenu = () => import('../../components/ui/BurgerMenu.vue' /* webpackChunkName: "components/burger-menu" */).then(c => wrapFunctional(c.default || c))
export const BurgerMenuTrigger = () => import('../../components/ui/BurgerMenuTrigger.vue' /* webpackChunkName: "components/burger-menu-trigger" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/ui/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Comet = () => import('../../components/ui/Comet.vue' /* webpackChunkName: "components/comet" */).then(c => wrapFunctional(c.default || c))
export const CookiesBar = () => import('../../components/ui/CookiesBar.vue' /* webpackChunkName: "components/cookies-bar" */).then(c => wrapFunctional(c.default || c))
export const DarkModeSwitcher = () => import('../../components/ui/DarkModeSwitcher.vue' /* webpackChunkName: "components/dark-mode-switcher" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/ui/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/ui/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const IconPlusMinus = () => import('../../components/ui/IconPlusMinus.vue' /* webpackChunkName: "components/icon-plus-minus" */).then(c => wrapFunctional(c.default || c))
export const ImageDecoration = () => import('../../components/ui/ImageDecoration.vue' /* webpackChunkName: "components/image-decoration" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/ui/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const InputUpload = () => import('../../components/ui/InputUpload.vue' /* webpackChunkName: "components/input-upload" */).then(c => wrapFunctional(c.default || c))
export const LightGallery = () => import('../../components/ui/LightGallery.vue' /* webpackChunkName: "components/light-gallery" */).then(c => wrapFunctional(c.default || c))
export const OutlineContentBlock = () => import('../../components/ui/OutlineContentBlock.vue' /* webpackChunkName: "components/outline-content-block" */).then(c => wrapFunctional(c.default || c))
export const OutlineGrid = () => import('../../components/ui/OutlineGrid.vue' /* webpackChunkName: "components/outline-grid" */).then(c => wrapFunctional(c.default || c))
export const OutlineGridItem = () => import('../../components/ui/OutlineGridItem.vue' /* webpackChunkName: "components/outline-grid-item" */).then(c => wrapFunctional(c.default || c))
export const Room = () => import('../../components/ui/Room.vue' /* webpackChunkName: "components/room" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/ui/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const Subheader = () => import('../../components/ui/Subheader.vue' /* webpackChunkName: "components/subheader" */).then(c => wrapFunctional(c.default || c))
export const Switcher = () => import('../../components/ui/Switcher.vue' /* webpackChunkName: "components/switcher" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/ui/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Textarea = () => import('../../components/ui/Textarea.vue' /* webpackChunkName: "components/textarea" */).then(c => wrapFunctional(c.default || c))
export const PitchForm = () => import('../../components/contact/pitch-form/PitchForm.vue' /* webpackChunkName: "components/pitch-form" */).then(c => wrapFunctional(c.default || c))
export const PitchFormItem = () => import('../../components/contact/pitch-form/PitchFormItem.vue' /* webpackChunkName: "components/pitch-form-item" */).then(c => wrapFunctional(c.default || c))
export const PitchFormProgress = () => import('../../components/contact/pitch-form/PitchFormProgress.vue' /* webpackChunkName: "components/pitch-form-progress" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
